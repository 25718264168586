import React, { useState, useEffect, FC } from 'react';
import UserItem from '../UserItem';

interface Props {
	data?: any[];
	is_endgame?: boolean;
}

const InfinityScroll: FC<Props> = ({ data, is_endgame }) => {
	const PAGINATION = 50;
	// const [players, setPlayers] = useState(data.slice(0, PAGINATION));
	const [players, setPlayers] = useState(data);
	const [isFetching, setIsFetching] = useState(false);
	const [isShowMore, setIsShowMore] = useState(true);
	// console.log(players, 'dasdas');

	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
	// 	return () => window.removeEventListener('scroll', handleScroll);
	// }, []);

	// useEffect(() => {
	// 	if (!isFetching) return;
	// 	setPlayers(prevState => {
	// 		if (prevState.length + PAGINATION > data.length) {
	// 			setIsShowMore(false);
	// 			return data;
	// 		}
	// 		return [
	// 			...prevState,
	// 			...data.slice(
	// 				players.length,
	// 				prevState.length + PAGINATION <= data.length ? players.length + PAGINATION : data.length,
	// 			),
	// 		];
	// 	});
	// 	setIsFetching(false);
	// }, [isFetching]);

	// function handleScroll() {
	// 	// if (
	// 	// 	window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight ||
	// 	// 	isFetching
	// 	// )
	// 	// 	return;
	// 	setIsFetching(true);
	// }

	return (
		<div>
			<div className="block-inner">
				{players.map(player => (
					<UserItem
						key={player.id}
						is_endgame={is_endgame}
						userId={player?.user?.id}
						history={player.history}
						avatar={player?.thumb?.url || player?.avatar}
						name={player?.name}
						money={player.money}
						max_life={player?.max_life}
						remaining_life={player?.remaining_life}
					/>
				))}
			</div>
			{/* {isShowMore && (
				<div
					onClick={handleScroll}
					style={{
						color: 'yellow',
						textAlign: 'center',
						fontWeight: 'bold',
						cursor: 'pointer',
						padding: '8px 0',
						fontSize: '18px',
					}}
				>
					Xem thêm...
				</div>
			)} */}
		</div>
	);
};

export default InfinityScroll;
