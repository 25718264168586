import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';

import LoadingView from 'src/components/LoadingView';
import GameDashboard from 'src/views/GameDashboard/screens/GameDashboard';
import GameDashboardStart from 'src/views/GameDashboard/screens/GameDashboardStart';

import { parseQuery } from 'src/helpers';
import Apis from 'src/services/apis';

const routes = [
	{ path: '/', name: 'Game Dashboard', Component: GameDashboardStart },
	{ path: '/game/:code', name: 'Game Dashboard', Component: GameDashboard },
];

const Web: React.FC = () => {

	return (
		<Router>
			<Switch>
				{routes.map(({ path, Component }: any) => (
					<Route key={path} exact path={path}>
						{navigation => {
							return <Component navigation={navigation} />;
						}}
					</Route>
				))}
			</Switch>
		</Router>
	);
};

export default Web;
