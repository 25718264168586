import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import socketIO from 'socket.io-client';
import _ from 'lodash';
import Apis from 'src/services/apis';
// import { CSVLink } from 'react-csv';

import LunarNewYear from '../components/Template/GameDashboard/LunarNewYear';
import Custom from '../components/Template/GameDashboard/Custom';
import LoadingView from 'src/components/LoadingView';
import { useEventCallback, useStates } from 'src/helpers';
import moment from 'moment-timezone';
import { GameReward, GameDetail } from 'src/interfaces/db';

import 'src/lixi.css';
import 'src/lichsulixi.css';
import 'src/bxhlixi.css';
import 'src/template1.css';
import { useParams } from 'react-router-dom';

interface GameDashboardProps {}

interface Params {
	code: string;
}

const GameDashboard: React.FC<GameDashboardProps> = ({}) => {
	const usersCache = JSON.parse(localStorage.getItem('allUsers')) || {};
	const { code } = useParams<Params>();

	const [mainLoading, setMainLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [is_game_started, setIsGameStarted] = useState(false);
	const [is_game_ended, setIsGameEnded] = useState(false);
	const [gameDetail, setGameDetail] = useStates<GameDetail>();
	const [endGame, setEndGame] = useState(true);
	const [error, setError] = useState(null);

	const audioRef = useRef<any>();

	const [players, setPlayers] = useStates([]);
	const [playersEndGame, setPlayersEndGame] = useStates([]);

	const [playerCounter, setPlayerCounter] = useState<number>(0);

	const { data: { game_id = '', game_access_code: access_code = '' } = {} } = Apis.getWebInfo() || {};

	const onLoad = useEventCallback(async () => {
		try {
			setError(null);

			const game_detail = await Apis.gameDetailWeb();

			await Apis.getAllUsers();

			/**
			 * Game Detail
			 */
			game_detail.game.rewards.sort((a, b) => b.money - a.money);
			let gameDetailRewards = game_detail.game.rewards.map(item => {
				return {
					...item,
					user: item.user_id ? usersCache[item.user_id] : null,
				};
			});
			setGameDetail({
				...game_detail.game,
				rewards: gameDetailRewards,
			});

			const current_time = moment();
			const start_time = moment.unix(game_detail.game.start_time);
			const end_time = moment.unix(game_detail.game.end_time);

			setEndGame(false);

			if (current_time.unix() < start_time.unix()) {
				/**
				 * Game Pending
				 */

				setIsGameStarted(false);
			} else {
				if (current_time.unix() > end_time.unix()) {
					/**
					 * End Game
					 */
					setIsGameStarted(true);
					setIsGameEnded(true);
					setEndGame(true);
				} else {
					/**
					 * In Game
					 */
					if (!is_game_ended && gameDetailRewards) {
						setPlayers(gameDetailRewards);
						setIsGameStarted(true);
					}
				}
			}
		} catch (e) {
			setError(e.message);
			setLoading(false);
		}
	});
	// console.log('players', players);

	useEffect(() => {
		if (!is_game_ended) {
			setPlayers(gameDetail?.rewards);
		}
	}, [players?.length, gameDetail?.rewards?.length, is_game_ended]);

	useEffect(() => {
		let socket;
		if (!endGame) {
			socket = socketIO(process.env.REACT_APP_SOCKET, {
				transports: ['websocket'],
				forceNew: true,
			});

			socket.connect();

			socket.on('connect', () => {
				console.log('Socket connected!');
			});
			// console.log('players:', players);
			socket.emit('join', {
				ws_id: gameDetail.workspace_id,
			});

			socket.on(`game_${gameDetail.workspace_id}_${gameDetail.access_code}`, res => {
				const { info } = res;
				// console.log('info: ', info);
				if (!!players.length) {
					if (info?.type === 'swap') {
						// console.log('take: ', info?.rewards.take.user?.id || 'hóng', info?.rewards.take.money);
						// console.log('betake: ', info?.rewards.betake.user?.id || 'hóng', info?.rewards.betake.money);
						// console.log('-.-.-.-.-.-.-.-.-.-.-.-.-.-.-');

						let indexTake = players.findIndex(item => item.id === info.rewards?.take?.id);
						let indexBeTake = players.findIndex(item => item.id === info.rewards?.betake?.id);

						players.splice(indexTake, 1, {
							...info.rewards?.take,
							remaining_life: info?.rewards.take?.life,
						});
						players.splice(indexBeTake, 1, {
							...info.rewards?.betake,
							remaining_life: info?.rewards.betake?.life,
						});

						setPlayers(players);
					}
					if (info?.type === 'down_life') {
						// console.log('-------down_life -------');
						let indexDownLife = players.findIndex(item => item.id === info.reward?.id);

						players.splice(indexDownLife, 1, {
							...info?.reward,
							max_life: info?.max_life,
							remaining_life: info?.remaining_life,
						});
						setPlayers(players);
					}
					if (info?.type === 'join') {
						// console.log('---------JOIN----------');
						let indexReward = players.map(item => item.id).indexOf(info.reward?.id);

						players.splice(indexReward, 1, {
							...info.reward,
							remaining_life: info.life,
						});
						setPlayers(players);
					}
				}
			});
			setLoading(false);
		}
		return () => {
			if (socket) socket.disconnect();
		};
	}, [endGame, players?.length]);

	useEffect(() => {
		(async () => {
			try {
				await Apis.setWebInfo(code);

				await Apis.setTemplateConfig();
				const count =
					((window.innerHeight - (Apis.getTemplateConfig().theme === 'tet_holiday' ? 240 : 336) - 100) /
						105) *
					3;
				setPlayerCounter(count - (count % 3));
			} catch (error) {
				setError('Game không tồn tại. Nhập lại mã game !');
				await Apis.setTemplateConfig();
				setLoading(false);
			}
			setMainLoading(false);
		})();
	}, []);

	useEffect(() => {
		if (!mainLoading && access_code && game_id) {
			onLoad().catch();
		}
	}, [mainLoading]);

	useEffect(() => {
		if (!mainLoading && is_game_started) {
			// console.log('audioRef.current', audioRef.current);
			audioRef.current && audioRef.current.play();
		}
	}, [is_game_started, mainLoading]);

	const onEndGame = useEventCallback(async () => {
		try {
			const result = await Apis.getGameResultWeb({ history: true, top: 24 });
			setPlayersEndGame(_.orderBy(result.rewards, 'money', 'desc'));

			setLoading(false);
		} catch (e) {}
	});

	const onEndGameDownload = useEventCallback(async () => {
		try {
			const result = await Apis.getGameResultWeb();

			// let rewards = [];
			// for (let reward of result.rewards) {
			// 	rewards.push({
			// 		id: reward.user_id,
			// 		...reward,
			// 	});
			// }

			setPlayers(_.orderBy(result.rewards, 'money', 'desc'));
			setPlayerCounter(result.rewards.length);
			setLoading(false);
		} catch (e) {}
	});

	// const [checkServer] = useState( false);

	useEffect(() => {
		let idTimeout;
		if (!mainLoading && is_game_ended) {
			setLoading(true);

			const checkServer = JSON.parse(sessionStorage.getItem(gameDetail.game_id.toString())) || false;

			// setTimeout(() => {
			//     // onEndGameDownload().catch(() => {});
			// 	setLoading(false);
			// }, 2000);
			if (!checkServer) {
				idTimeout = setInterval(() => {
					(async () => {
						const endGame = await Apis.checkServerFinished(gameDetail.game_id);
						if (endGame) {
							sessionStorage.setItem(gameDetail.game_id.toString(), 'true');
							window.location.reload();
						}
					})();
				}, 1000);
			}

			if (checkServer) {
				onEndGame().catch(() => {});
			}
		}
		return () => clearTimeout(idTimeout);
	}, [is_game_ended, mainLoading]);

	// if (detectmob()) {
	// 	return (
	// 		<div className="bxh bxhlixi">
	// 			<div
	// 				style={{
	// 					height: ' 100vh',
	// 					justifyContent: 'center',
	// 					alignItems: 'center',
	// 					display: 'flex',
	// 					padding: 30,
	// 					color: '#fff',
	// 					textAlign: 'center',
	// 					fontWeight: 700,
	// 				}}
	// 			>
	// 				Bảng xếp hạng không khả dụng trên trình duyệt thiết bị di động, vui lòng mở bằng trình duyệt của máy
	// 				tính
	// 			</div>
	// 		</div>
	// 	);
	// }

	const onClickJoinGame = useEventCallback(() => {
		const deeplink = `https://d.acheckin.vn/app?p=app.holiday&d=${access_code}&wi=${gameDetail.workspace_id}`;

		window.open(
			`https://app.acheckin.vn/?link=${encodeURIComponent(
				deeplink,
			)}&apn=vn.acheckin&isi=1451269978&ibi=vn.acheckin&efr=1`,
		);
	});

	if (mainLoading) {
		return <LoadingView />;
	}

	return (
		<>
			{loading && <LoadingView />}
			{/* {Apis.getTemplateConfig()?.theme === 'tet_holiday' ? ( */}

			<LunarNewYear
				loading={loading}
				error={error}
				is_game_started={is_game_started}
				game_detail={gameDetail}
				setPlayers={setPlayers}
				setIsGameStarted={setIsGameStarted}
				is_game_ended={is_game_ended}
				setIsGameEnded={setIsGameEnded}
				players={players?.slice(0, playerCounter)}
				playersEndGame={playersEndGame}
				onClickJoinGame={onClickJoinGame}
			/>
			{/* ) : (
				<Custom
					loading={loading}
					error={error}
					is_game_started={is_game_started}
					game_detail={game_detail}
					setPlayers={setPlayers}
					setIsGameStarted={setIsGameStarted}
					is_game_ended={is_game_ended}
					setIsGameEnded={setIsGameEnded}
					players={players.slice(0, playerCounter)}
					onClickJoinGame={onClickJoinGame}
				/>
			)} */}
		</>
	);
};

export default GameDashboard;
