import React, { useEffect, useState } from 'react';
import { formatMoney, get } from 'src/helpers';
import Apis from 'src/services/apis';
import { User } from 'src/interfaces/db';
import NameUserItem from './NameUserItem';
import AvatarUserItem from './AvatarUserItem';
import MoneyUserItem from './MoneyUserItem';

interface UserItemProps {
	game_reward?: any;
	is_endgame: boolean;
	history?: any;
	avatar?: string;
	name?: string;
	money?: number;
	max_life?: number;
	remaining_life?: number;
	userId?: number;
	is_game_ended?: boolean;
}

const UserItem: React.FC<UserItemProps> = ({
	userId,
	history,
	max_life,
	avatar,
	name,
	money,
	remaining_life,
	is_game_ended,
}) => {
	const {
		configs: { common_configs },
	} = Apis.getTemplateConfig();

	const renderMore = () => {
		const histories = history || [];

		if (histories.length === 0) return null;

		const list_user = [];
		let count_user_more = 0;

		for (let history of histories) {
			if (history) {
				if (list_user.length < 4) {
					list_user.push(history);
				} else {
					count_user_more++;
				}
			}
		}

		if (!list_user.length) return null;

		return (
			<div className="robListUsers">
				{list_user.map(user => {
					if (!user.data.from?.avatar) return null;
					return (
						<div key={user.id} className="robUser">
							<img src={user.data.from?.avatar} width="22" />
						</div>
					);
				})}
				{count_user_more > 0 && <div className="more">+{count_user_more}</div>}
			</div>
		);
	};

	return (
		<div className="item">
			<AvatarUserItem
				src={
					avatar ||
					(Apis.getTemplateConfig().theme === 'tet_holiday'
						? common_configs?.logo?.value || require('src/image/btn-lac.png')
						: common_configs?.logo?.value || require('src/image/template1/btn-lac.png'))
				}
			/>
			{/* <h2 style={{ color: 'white', zIndex: 1111, marginRight: 10 }}>{userId}</h2> */}

			<div className="info-item">
				<NameUserItem name={name || 'Đang hóng'} />
				{renderMore()}
			</div>
			<MoneyUserItem money={money} />
			{remaining_life && userId && !is_game_ended && (
				<div className="defense-life">Còn {remaining_life || 0} mạng</div>
			)}
		</div>
	);
};

export default React.memo(UserItem);
