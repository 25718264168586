import React from 'react';
import Apis from 'src/services/apis';

const Custom: React.FC = () => {
	// const {configs: {common_configs, pages}} = Apis.getTemplateConfig();
	return (
		<div className="bxh bxhlixi template template-1">
			<div
				style={{
					zIndex: 9999,
					position: 'absolute',
					width: '100vw',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					className="bg-image bg-cover"
					style={{ backgroundImage: `url(${require('src/image/template1/bg.jpg')} )` }}
				></div>
				<div className="logo mt-100 p-relative">
					<div className="logo_Inner">
						<img className="logo_Bg" src={require('src/image/template1/logo.png')} alt="" />
					</div>
				</div>
				<div className="el1 p-absolute">
					<img src={require('src/image/template1/el1.png')} alt="" />
				</div>
				<div className="el2 p-absolute">
					<img src={require('src/image/template1/el2.png')} alt="" />
				</div>
			</div>
			<audio autoPlay loop>
				<source src={require('src/music/happy_birthday_to_you.mp3')} type="audio/mp4" />
			</audio>
		</div>
	);
};

export default Custom;
