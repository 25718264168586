import React, { FC } from 'react';

interface NameUserItemProps {
	name: string;
}

const NameUserItem: FC<NameUserItemProps> = ({ name }) => {
	return (
		<div title={name} className="nameUser-item">
			{name}
		</div>
	);
};

export default React.memo(NameUserItem);
