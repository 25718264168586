import React, { FC } from 'react';
import { formatMoney } from '../../../../helpers/index';

interface MoneyUserItemProps {
	money: number;
}

const MoneyUserItem: FC<MoneyUserItemProps> = ({ money }) => {
	return (
		<div className={formatMoney(money || 0).length < 12 ? 'valueUserItem' : 'valueUserItem2'}>
			{formatMoney(money || 0)}
		</div>
	);
};

export default React.memo(MoneyUserItem);
