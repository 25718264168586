import React from 'react';

import Animation from '../../Animation';
import Apis from 'src/services/apis';

const LunarNewYear: React.FC = () => {
	const {
		configs: { pages },
	} = Apis.getTemplateConfig();
	return (
		<div
			className="bxh bxhlixi"
			style={{
				backgroundImage: `url(${pages?.appGameResult?.bgbxh?.value || require('src/image/bgbxh.jpg')})`,
			}}
		>
			<Animation />
			<div
				style={{
					zIndex: 9999,
					position: 'absolute',
					width: '100vw',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div className="logoTet">
					<div className="logoTet_Inner">
						<img
							className="logoTet_Bg"
							src={pages?.appGameResult?.tet_bg?.value || require('src/image/tet_bg.png')}
							alt=""
						/>
						{pages?.appGameResult?.tet_bg?.value ===
							'https://acheckin-lixi-game.s3.kstorage.vn/uploads/themes/configs/tet_bg.png' && (
							<img
								className="logoTet_Circle"
								src={pages?.appGameResult?.tet_circle?.value || require('src/image/tet_circle.png')}
								alt=""
							/>
						)}
					</div>
				</div>
			</div>
			<audio autoPlay loop muted>
				<source src={require('src/music/lunar_new_year.mp3')} type="audio/mp3" />
			</audio>
		</div>
	);
};

export default LunarNewYear;
