import React from 'react';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import Animation from '../../Animation';
import CountDown from 'src/components/CountDown';
import QRCode from 'qrcode.react';
import UserItem from '../../UserItem';

import Apis from 'src/services/apis';
import { detectmob, get } from 'src/helpers';

import { GameReward, GameDetail } from 'src/interfaces/db';
import LazyLoad from 'react-lazyload';
import InfinityScroll from '../../InfinityScroll/index';

interface Params {
	access_code: string;
	game_id: string;
}

interface LunarNewYearProps {
	loading: boolean;
	error: string;
	is_game_started: boolean;
	game_detail: GameDetail;
	setPlayers: (state: GameReward[]) => void;
	setIsGameStarted: (state: boolean) => void;
	is_game_ended: boolean;
	setIsGameEnded: (state: boolean) => void;
	players: any;
	playersEndGame: any;
	onClickJoinGame: () => void;
}

const LunarNewYear: React.FC<LunarNewYearProps> = ({
	loading,
	error,
	is_game_started,
	game_detail,
	setPlayers,
	setIsGameStarted,
	is_game_ended,
	setIsGameEnded,
	players,
	playersEndGame,
	onClickJoinGame,
}) => {
	const {
		configs: { pages },
		audio,
	} = Apis.getTemplateConfig();

	const usersCache = JSON.parse(localStorage.getItem('allUsers')) || {};

	return (
		<div
			className="bxh bxhlixi"
			style={{
				backgroundImage: `url(${pages?.appGameResult?.bgbxh?.value || require('src/image/bgbxh.jpg')})`,
			}}
		>
			<Animation />
			{!loading && error && (
				<div
					style={{
						width: '100vw',
						height: '100vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#fff',
						fontWeight: 700,
						fontSize: 30,
					}}
				>
					{error}
				</div>
			)}
			{!loading && !error && (
				<div className="innerBxh">
					<div className="innerBxh_Content">
						{!is_game_started && (
							<div>
								<div className="header_user">
									<img src={require('src/image/top-bg.png')} alt="" />
								</div>
								<div className="qrCode_Inner">
									<CountDown
										isPC={false}
										title="Game sẽ bắt đầu trong"
										time={get(game_detail, (e: { start_time: number }) => e.start_time * 1000, 0)}
										onComplete={async () => {
											const game_detail = await Apis.gameDetailWeb();
											if (game_detail.game.rewards) {
												// let sorted_game_rewards = [];

												// game_detail.game.rewards.forEach(game_reward => {
												// 	sorted_game_rewards.push({
												// 		id: game_reward.id,
												// 		...game_reward,
												// 		...game_reward.attributes,
												// 	});
												// });

												// sorted_game_rewards = _.orderBy(sorted_game_rewards, 'money', 'desc');

												// setPlayers(sorted_game_rewards);
												game_detail.game.rewards.sort((a, b) => b.money - a.money);
												let newPlayers = game_detail.game.rewards.map(item => {
													return {
														...item,
														user: item.user_id ? usersCache[item.user_id] : null,
													};
												});

												setPlayers(newPlayers);
												setIsGameStarted(true);
											}
										}}
									/>
									<div className="qrCode">
										<QRCode
											value={`acheckin://ap?p=${process.env.REACT_APP_BUNDLE_ID}&d=${
												Apis.getWebInfo()?.data?.game_access_code
											}&wi=${game_detail.workspace_uri}`}
											size={320}
										/>
									</div>
								</div>
								<div className="help">
									Mở ACheckin để quét QRCode hoặc nhập mã{' '}
									<span style={{ fontSize: 30 }}>{Apis.getWebInfo()?.data?.game_access_code}</span> để
									chơi game
								</div>
								<audio autoPlay loop>
									<source src={require('src/music/lunar_new_year.mp3')} type="audio/mp3" />
								</audio>
							</div>
						)}

						{is_game_started && (
							<>
								<div className="header_user">
									<img src={require('src/image/top.png')} alt="" />
									<div className="titleName" style={{ position: 'relative', left: 0, bottom: 0 }}>
										{get(game_detail, (e: { name: string }) => e.name)}
									</div>
								</div>
								{!is_game_ended && (
									<CountDown
										isPC={true}
										title="Game sẽ kết thúc trong"
										time={get(game_detail, (e: { end_time: number }) => e.end_time * 1000, 0)}
										onComplete={() => {
											setIsGameEnded(true);
										}}
									/>
								)}
								<div className="block-2">
									{is_game_ended && !!playersEndGame?.length && (
										<div className="welcome">
											{game_detail.custom
												? game_detail.custom
												: // appGameCreate?.greeting?.value ||
												  'Bằng tất cả sự chân thành của mình, tôi xin cảm ơn những đóng góp của tất cả các thành viên trong Công ty trong suốt một năm qua. Chúc anh/chị/em một năm mới an khang,thịnh vượng.'}
										</div>
									)}
									{/* {players.map(
										item =>
											(item.user_id || item.user?.id) && (
												<span
													key={item.id}
													style={{
														color: 'white',
														zIndex: 9999,
														marginLeft: 10,
														fontSize: 24,
													}}
												>
													{item.user?.id} - {item.money}
												</span>
											),
									)} */}
									{!!players.length && !is_game_ended && (
										<div className="block-inner">
											{/* {players.map(player => (
											<UserItem is_endgame={is_game_ended} key={player.id} game_reward={player} />
										))} */}
											{players.map(player => (
												<LazyLoad key={player.id}>
													<UserItem
														is_endgame={is_game_ended}
														userId={player?.user?.id}
														history={player.history}
														avatar={player.user?.thumb || player.user?.avatar}
														name={player.user?.name}
														money={player.money}
														max_life={player?.max_life}
														remaining_life={player?.remaining_life}
													/>
												</LazyLoad>
											))}
										</div>
									)}
									{is_game_ended && !!playersEndGame?.length && (
										<InfinityScroll data={playersEndGame} is_endgame={is_game_ended} />
									)}
								</div>
								{!is_game_ended && (
									<audio autoPlay loop>
										<source
											src={audio || require('src/music/Xo-So-Mien-Bac-Nhac-Chuong-V-A-V-A.mp3')}
											type="audio/mp3"
										/>
									</audio>
								)}
								{is_game_ended && (
									<audio autoPlay loop>
										<source
											src={audio || require('src/music/lunar_new_year.mp3')}
											type="audio/mp3"
										/>
									</audio>
								)}
							</>
						)}
					</div>
				</div>
			)}
			{is_game_ended && playersEndGame.length > 0 && !detectmob() && (
				<div className="btnFooter">
					<CSVLink
						className="btnFooter_Item"
						data={playersEndGame}
						headers={[
							{ label: 'Mã nhân viên', key: 'staff_id' },
							{ label: 'Họ và tên', key: 'name' },
							{ label: 'Số tiền lì xì', key: 'money' },
						]}
						filename="ketqua.csv"
					>
						Xuất dữ liệu
					</CSVLink>
				</div>
			)}
		</div>
	);
};

export default LunarNewYear;
