import React, { FC, useEffect } from 'react';

interface AvatarUserItemProps {
	src: string;
}

const AvatarUserItem: FC<AvatarUserItemProps> = ({ src }) => {
	return (
		<div className="image-user">
			<img src={src} />
		</div>
	);
};

export default React.memo(AvatarUserItem);
