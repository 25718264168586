import React from 'react';
import Apis from 'src/services/apis';

interface Props {
    buttonShake?: string;
}

const LoadingView: React.FC<Props> = ({buttonShake}) => {
	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 9999,
				width: '100vw',
				height: '100vh',
				display: 'flex',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: 'rgb(135 114 114 / 80%)'
			}}
		>
			<div style={{ width: 120, height: 120 }}>
				<img
					className="logo-circle"
					src={
						Apis.getTemplateConfig()?.theme === 'tet_holiday'
							? require('src/image/btn-lac.png')
							: (buttonShake || require('src/image/template1/btn-lac.png'))
					}
					alt=""
				/>
			</div>
		</div>
	);
};

export default React.memo(LoadingView);
