import React, { useEffect, useState } from 'react';

import LunarNewYear from 'src/views/GameDashboard/components/Template/GameDashboardStart/LunarNewYear';
import Custom from 'src/views/GameDashboard/components/Template/GameDashboardStart/Custom';

import Apis from 'src/services/apis';

import 'src/lixi.css';
import 'src/lichsulixi.css';
import 'src/bxhlixi.css';
import 'src/template1.css';
import LoadingView from 'src/components/LoadingView';

const GameDashboardStart: React.FC = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				await Apis.setTemplateConfig();
			} catch (error) {}
			setLoading(false);
		})();
	}, []);

	if (loading) return <LoadingView />;

	return <>{Apis.getTemplateConfig()?.theme === 'tet_holiday' ? <LunarNewYear /> : <Custom />}</>;
};

export default GameDashboardStart;
