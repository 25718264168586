import querystring from 'querystring';
import url, { UrlObject } from 'url';
import { ACheckinSDK, UserWorkspaceInfo } from '@acheckin.io/acheckin-sdk';
import {
	ArgsToChangeReward,
	ArgsToCreateGame,
	ArgsToJoinGame,
	ChangeRewardResponse,
	CreateGameResponse,
	GameDetailResponse,
	GameResultResponse,
	JoinGameResponse,
	GamerInfo,
	ThemeConfig,
	WebInfoResponse,
	ConfigsThemeRes,
} from 'src/interfaces/apis';
import Exception from './exception';
import { isArray } from 'lodash';
import { User } from 'src/interfaces/db';

class Apis {
	private access_token: string;
	private user_info: UserWorkspaceInfo;
	private gamer_info: GamerInfo;
	private is_joined_game: boolean = false;
	private is_change_reward = false;
	private last_time_change_reward: number = 0;
	private start_from_qrcode = false;
	private last_game_id;
	private template_config: ThemeConfig;
	private webInfo: WebInfoResponse;
	private thieferUser: User[] = [];
	private robberUser: User[] = [];
	private noChangeReward: boolean;
	private isFinishedServer: boolean;

	public getNoChangeReward = () => {
		return this.noChangeReward;
	};

	public getServerFinished = () => {
		return this.isFinishedServer;
	};

	public setNoChangeReward = (val: boolean) => {
		return (this.noChangeReward = val);
	};

	public getThiefer = () => {
		return JSON.parse(localStorage.getItem('thiefer'));
	};
	public getRobber = () => {
		return JSON.parse(localStorage.getItem('robber'));
	};
	public setThiefer = (thief: User) => {
		if (!this.thieferUser.map(item => item?.id).includes(thief?.id)) {
			this.thieferUser.push(thief);
		}
		localStorage.setItem('thiefer', JSON.stringify(this.thieferUser));
		// return this.thieferUser;
	};
	public setRobber = (rob: User) => {
		if (!this.robberUser.map(item => item?.id).includes(rob?.id)) {
			this.robberUser.push(rob);
		}
		localStorage.setItem('robber', JSON.stringify(this.robberUser));
		// return this.robberUser;
	};
	public resetThiefer = () => {
		localStorage.setItem('thiefer', JSON.stringify([]));
		return (this.thieferUser = []);
	};
	public resetRobber = () => {
		localStorage.setItem('robber', JSON.stringify([]));
		return (this.robberUser = []);
	};

	public getTemplateConfig = () => {
		return this.template_config;
	};

	public getWebInfo = () => {
		return this.webInfo;
	};

	public init = async () => {
		try {
			this.last_game_id = await ACheckinSDK.getItem('last_game_id');
		} catch (e) {}
	};

	public getLastGameId = () => {
		return this.last_game_id;
	};

	public isStartFromQrCode = () => {
		return this.start_from_qrcode;
	};

	public setStartFromQrCode = () => {
		this.start_from_qrcode = true;
	};

	public setLastTimeChangeReward = (time: number) => {
		this.last_time_change_reward = time;
	};

	public getLastTimeChangeReward = (): number => {
		return this.last_time_change_reward;
	};

	public setAccessToken = (access_token: string) => {
		this.access_token = access_token;
	};

	public setUserInfo = user_info => {
		this.user_info = user_info;
	};

	public getUserInfo = () => {
		return this.user_info;
	};

	public getGamerInfo = () => {
		return this.gamer_info;
	};

	public setJoinedGame = (value: boolean) => {
		this.is_joined_game = value;
	};

	public isJoinedGame = () => {
		return this.is_joined_game;
	};

	public isChangeReward = () => {
		return this.is_change_reward;
	};

	public setChangeReward = (value: boolean) => {
		this.is_change_reward = value;
	};

	public setAppThemesConfig = async () => {
		const template_config: ThemeConfig = await this.call({
			path: 'config/theme',
			method: 'GET',
		});
		let configOverride: ConfigsThemeRes =
			(isArray(template_config.configs) && template_config.configs.length === 0) || template_config.configs === {}
				? { common_configs: {}, pages: {} }
				: template_config.configs;

		return (this.template_config = { ...template_config, configs: configOverride, theme: 'tet_holiday' });
	};

	public getGamerInfoFetch = async () => {
		const gamer_info = await this.call({
			path: '/game/user_info',
			method: 'GET',
		});
		this.gamer_info = gamer_info;
	};

	public createGame = (options: ArgsToCreateGame): Promise<CreateGameResponse> => {
		return this.call({
			path: '/game/create',
			method: 'POST',
			body: options,
		});
	};

	public joinGame = async (options: ArgsToJoinGame): Promise<JoinGameResponse> => {
		const response = await this.call({
			path: '/game/join',
			method: 'POST',
			body: options,
		});

		try {
			this.last_game_id = response.access_code;
			await ACheckinSDK.setItem('last_game_id', `${response.access_code}`);
		} catch (e) {
			console.log(e);
		}

		return response;
	};

	public changeReward = (options: ArgsToChangeReward): Promise<ChangeRewardResponse> => {
		return this.call({
			path: '/game/change_reward',
			method: 'POST',
			body: options,
		});
	};

	public gameDetail = (options: { access_code: string }): Promise<GameDetailResponse> => {
		return this.call({
			path: '/game/detail',
			method: 'GET',
			qs: options,
		});
	};

	public getGameResult = (options: {
		access_code: string;
		history?: boolean;
		top?: number;
	}): Promise<GameResultResponse> => {
		return this.call({
			path: '/game/result',
			method: 'POST',
			body: options,
		});
	};

	public setTemplateConfig = async () => {
		const idWorks = ['undefined', undefined, null, 'null'];

		const id =
			this.webInfo?.data?.workspace || !idWorks.includes(localStorage.getItem('workspace'))
				? localStorage.getItem('workspace')
				: 'appota.acheckin.vn';

		const config_theme: ThemeConfig = await this.call({
			path: '/web/workspace',
			method: 'GET',
			qs: { id },
		});
		if (!config_theme) {
			return (this.template_config = {
				...config_theme,
				configs: { common_configs: {}, pages: {} },
				theme: 'tet_holiday',
				status: true,
			});
		}

		let configOverride: ConfigsThemeRes =
			(isArray(config_theme.configs) && config_theme.configs.length === 0) || config_theme.configs === {}
				? { common_configs: {}, pages: {} }
				: config_theme.configs;

		this.template_config = {
			...config_theme,
			configs: configOverride || { common_configs: {}, pages: {} },
			theme: 'tet_holiday',
		};
	};

	public setWebInfo = async (code: string) => {
		const webInfo = code
			? await this.call({
					path: '/web/info',
					method: 'POST',
					body: { code },
			  })
			: null;

		localStorage.setItem('workspace', webInfo?.data?.workspace);
		return (this.webInfo = webInfo);
	};

	public gameDetailWeb = (): Promise<GameDetailResponse> => {
		const { data: { game_access_code: access_code = '', game_id = '' } = {} } = this.webInfo;
		return this.call({
			path: '/web/detail',
			method: 'GET',
			qs: { access_code, game_id },
		});
	};

	public getAllUsers = async () => {
		const {
			data: { code },
		} = this.webInfo;
		const res = await this.call({
			path: `/web/users`,
			method: 'GET',
			qs: { code },
		});
		if (res.status) {
			const usersList = res.users.reduce(
				(total, item) => ({
					...total,
					[item.id]: item,
				}),
				{},
			);

			localStorage.setItem('allUsers', JSON.stringify(usersList));
		}
	};

	public checkServerFinished = async (id: number) => {
		const res = await this.call({
			path: '/web/game_server_end',
			method: 'GET',
			qs: { game_id: id },
		});

		return (this.isFinishedServer = res?.status || false);
	};

	public getGameResultWeb = (options?: { history: boolean; top: number }): Promise<GameResultResponse> => {
		const { data: { game_access_code: access_code = '', game_id = '' } = {} } = this.webInfo;
		return this.call({
			path: '/web/result',
			method: 'POST',
			body: { ...options, access_code, game_id },
		});
	};

	private call = (options: { path: string; method?: 'POST' | 'GET'; qs?: any; body?: object }): Promise<any> => {
		return new Promise(async (resolve, reject) => {
			try {
				const url_object: UrlObject = {
					protocol: process.env.REACT_APP_PROTOCOL,
					hostname: process.env.REACT_APP_END_POINT,
					pathname: options.path,
				};

				if (options.qs) {
					url_object.search = querystring.stringify(options.qs);
				}

				const request_headers = {
					authorization: this.access_token,
				};

				const request_options: RequestInit = {
					method: options.method || 'GET',
				};

				if (options.body && typeof options.body === 'object') {
					request_options.body = JSON.stringify(options.body);
					request_headers['content-type'] = 'application/json';
				}

				request_options.headers = request_headers;

				try {
					const raw_response = await fetch(url.format(url_object), request_options);
					const json_response = await raw_response.json();

					if (json_response.status === false) {
						throw new Exception(json_response.message, json_response.err_code || 500);
					}

					return resolve(json_response);
				} catch (e) {
					throw new Exception(e.message, e.code);
				}
			} catch (e) {
				return reject(new Exception(e.message, e.code));
			}
		});
	};
}

export default new Apis();
